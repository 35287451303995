<template>
    <div class="main-recoveryPassword">
        <ui-card class="main-recoveryPassword__card mx-auto mb-5">
            <div class="text-center">
                <img class="w-50" src="https://pay.ecart.com/img/logo-negro.svg" alt="" />
            </div>
            <h5 class="main-recoveryPassword__title mb-0">Recovery password</h5>
            <form ref="formRecoveryPassword" @submit.prevent="submit">
                <ui-input
                    id="email"
                    name="email"
                    class="mb-4"
                    label="Email"
                    type="email"
                    placeholder="email@domain.com"
                    v-model:value="formData.email"
                    :field="v$.formData.email"
                />
                <ui-button
                    :loading="loading"
                    class="w-100"
                    type="submit"
                    variant="primary"
                >
                    Recovery
                </ui-button>
            </form>
            <router-link class="main-recoveryPassword__link" to="/login">Back to login</router-link>
        </ui-card>
        <p class="main-recoveryPassword__copy">
            &copy; 2022 EcartPay. All rights reserved.
        </p>
        <img class="main-recoveryPassword__hero" src="@/assets/img/login/hero.png" alt="Ecart Pay" />
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { api } from '@/services';

import UiButton from '@/components/ui/buttons/Button';
import UiCard from '@/components/ui/Card';
import UiInput from '@/components/ui/Input';

export default {
    components: {
        UiButton,
        UiCard,
        UiInput,
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            formData: {
                email: '',
                password: '',
            },
            loading: false
        };
    },
    validations () {
        return {    
            formData: {
                email: { required, email, $autoDirty: true },
                password: {$autoDirty: true },
            },
        };
    },
    methods: {
        async submit() {
            const validated = await this.v$.$validate();
            if (!validated) return;

            try {
                this.loading = true;
                const { data } = await api.recoveryPassword(this.formData);
                if (data.type === 'success') {
                    this.$toast.success(data.message);
                }else{
                    this.$toast.error(data.message);
                }
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss">
.main-recoveryPassword {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 3rem 0;
    position: relative;

    @media(max-width: 576px) {
        background-color: #ffffff;
        box-shadow: none;
    }

    &__card {
        width: 100%;
        max-width: 480px;
        position: relative;
        z-index: 2;

        @media(max-width: 576px) {
            background-color: transparent;
            box-shadow: none;
        }
    }

    &__title {
        color: $general-black;
        font-weight: 500;
        font-size: 16px;
    }

    &__link {
        color: $ecart-accent;
        font-weight: 500;
        text-decoration: none;
    }

    &__copy {
        color: rgba($general-black, 0.7);
        position: relative;
        z-index: 2;
    }

    &__hero {
        bottom: 0;
        height: auto;
        position: absolute;
        right: 0;
        width: 35vw;
        z-index: 1;

        @media(max-width: 576px) {
            display: none;
        }
    }
}
</style>